import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { TeacherDataTypes } from "data/types"

import React, { useState, useEffect, useRef, FC } from "react"

interface Props {
  onClick?: () => void
  onChange?: (value: string) => void
  className?: string
  defaultValue?: string
  headingText?: string

  data: TeacherDataTypes[]
}

const TeachersInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText,
  data
}) => {
  const [value, setValue] = useState("")
  const containerRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleSelectLocation = (item: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item)
      onChange && onChange(item)
    }, 0)
  }

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Nom du professeur"}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          <p className="block font-semibold text-base">
            {value ? "Sreach Result" : "Professeurs"}
          </p>
          <div className="mt-3">
            {data
              ?.filter((teacher) =>
                (teacher.firstName + " " + teacher.lastName)
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
              .map((teacher) => {
                return (
                  <div
                    className="py-2 mb-1 flex items-center space-x-3 text-sm"
                    onClick={() =>
                      handleSelectLocation(
                        `${teacher.firstName} ${teacher.lastName}`
                      )
                    }
                    key={teacher.id}
                  >
                    <EyeIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                    <span className="">
                      {teacher.firstName + " " + teacher.lastName}
                    </span>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeachersInput

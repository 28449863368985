import { DEMO_POSTS } from "data/posts"
import { PostDataType } from "data/types"
import React from "react"
import { Link } from "react-router-dom"
import Avatar from "shared/Avatar/Avatar"
import Badge from "shared/Badge/Badge"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import Comment from "shared/Comment/Comment"
import NcImage from "shared/NcImage/NcImage"
import SocialsList from "shared/SocialsList/SocialsList"
import Textarea from "shared/Textarea/Textarea"
import { Helmet } from "react-helmet"

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Technologies éducatives" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Pourquoi les Cours Particuliers en Ligne sont l'Avenir de
            l'Éducation
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Explorez comment les cours particuliers en ligne révolutionnent
            l'approche de l'éducation, offrant une flexibilité, une
            personnalisation et un accès à une expertise mondiale.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    Pr. Yassin Bouchda
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    5 min de lecture
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    )
  }

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          L'éducation est en pleine <strong>mutation</strong>. Avec l'avènement
          de la technologie numérique, les cours particuliers en ligne gagnent
          du terrain, promettant de <strong> transformer</strong> radicalement
          l'apprentissage. Cette évolution marque un tournant décisif dans la
          manière dont les connaissances sont acquises, partagées et appliquées.
        </p>
        <p>
          Les cours particuliers en ligne offrent une{" "}
          <strong>flexibilité</strong> et une <strong>personnalisation</strong>{" "}
          inégalées, permettant aux étudiants de bénéficier d'une éducation
          adaptée à leurs besoins spécifiques. Cette approche individualisée de
          l'apprentissage est essentielle pour favoriser la réussite scolaire et
          l'épanouissement personnel.
        </p>

        <h3>L'Ascension des Cours Particuliers en Ligne</h3>
        <p>
          La popularité croissante des cours particuliers en ligne n'est pas un
          hasard. Elle répond à une demande pour une éducation plus adaptative,
          qui transcende les <strong>limitations géographiques </strong>et
          temporelles. Ce mode d'apprentissage combine l'expertise d'enseignants
          qualifiés avec la commodité d'accéder aux cours de n'importe où, à
          tout moment.
        </p>
        <blockquote>
          <p>
            La quête de la connaissance est le moteur du progrès humain ; dans
            le domaine de l'education, cette quête se traduit par{" "}
            <strong> l'innovation</strong>
            continue qui façonne notre avenir.
          </p>
        </blockquote>
        <figure>
          <img
            src="https://images.unsplash.com/photo-1597933471507-1ca5765185d8?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>

        <h2>L'Impact sur les Résultats Académiques</h2>
        <p>
          Les études montrent que les étudiants qui suivent des cours
          particuliers en ligne obtiennent de meilleurs résultats académiques.
          Cette approche pédagogique innovante permet aux élèves de progresser à
          <strong> leur propre rythme</strong>, en bénéficiant d'une attention
          personnalisée et d'un soutien continu.
        </p>

        <h2>Vers un Avenir Plus Brillant</h2>
        <p>
          Les cours particuliers en ligne ouvrent de nouvelles perspectives
          d'apprentissage, offrant aux étudiants la possibilité de{" "}
          <strong> s'épanouir</strong> et de réaliser leur plein potentiel. En
          combinant la technologie numérique avec l'expertise pédagogique, ces
          cours révolutionnaires transforment l'éducation, ouvrant la voie à un
          avenir plus brillant pour tous.
        </p>
        <p>
          {" "}
          L'éducation en ligne n'est pas seulement une solution temporaire à un
          problème immédiat; elle représente{" "}
          <strong> l'avenir de l'apprentissage</strong>. En continuant d'innover
          et de s'adapter aux besoins des étudiants, les cours particuliers en
          ligne ont le potentiel de démocratiser l'accès à l'éducation,
          garantissant que chaque étudiant ait la possibilité de réaliser son
          plein potentiel.
        </p>

        <h2>Conclusion</h2>
        <p>
          Les cours particuliers en ligne sont l'avenir de l'éducation. En
          offrant une approche flexible, personnalisée et accessible à
          l'apprentissage, ces cours révolutionnaires transforment la manière
          dont les connaissances sont acquises et partagées. En embrassant cette
          évolution, nous pouvons créer un avenir plus brillant pour tous, où
          l'éducation est un droit, pas un privilège.
        </p>
      </div>
    )
  }

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Apprentissage à distance
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Enseignement personnalisé
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Technologies éducatives
        </a>
      </div>
    )
  }

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    )
  }

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    )
  }

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    )
  }

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    )
  }

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Cours PlusPlus || Personalized Academic Support</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.unsplash.com/photo-1585974738771-84483dd9f89f?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  )
}

export default BlogSingle

import React, { FC, useEffect, useState } from "react"
import BackgroundSection from "components/BackgroundSection/BackgroundSection"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { ApiService } from "service/ApiService"
import { CourseTypes, TeacherDataTypes } from "data/types"
import { useLocation } from "react-router-dom"
import Heading from "components/Heading/Heading"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import Avatar from "shared/Avatar/Avatar"
import NcImage from "shared/NcImage/NcImage"
import convertNumbThousand from "utils/convertNumbThousand"
import { ArrowRightIcon } from "@heroicons/react/24/outline"

export interface ListingStayMapPageProps {
  className?: string
}

const ListingPage: FC<ListingStayMapPageProps> = ({ className = "" }) => {
  const location = useLocation()

  const { studyLevelId, specialtyId, subjectId, teacherId } = location.state

  const [courseDetails, setCourseDetails] = useState<CourseTypes[]>([])

  const [teacherData, setTeacherData] = useState<TeacherDataTypes[]>([])

  const [displayCount, setDisplayCount] = useState(20)

  const handleShowMore = () => {
    // Increase the display count by 10 when "Show More" is clicked
    setDisplayCount((prevCount) => prevCount + 20)
  }

  useEffect(() => {
    if (!teacherId) {
      ApiService.listingSearch(studyLevelId, specialtyId, subjectId)
        .then((res) => {
          setCourseDetails([...res.data.data])
        })
        .catch((err) => console.log(err))
    }
  }, [location.state])

  useEffect(() => {
    if (teacherId) {
      ApiService.getTeacherById(teacherId)
        .then((res) => {
          setTeacherData([res.data.data])
        })
        .catch((err) => console.log(err))
    }
  }, [location.state])

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>Résultat de la recherche || Cours PlusPlus</title>
      </Helmet>

      <div className="container overflow-hidden">
        {/* SECTION */}
        <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-neutral-100 dark:bg-black dark:bg-opacity-20 " />
          {/* Search Details */}
          <div
            className={`nc-SectionGridAuthorBox relative`}
            data-nc-id="SectionGridAuthorBox"
          >
            <Heading
              desc="Voici les résultats adaptés à vos filtres de recherche sélectionnés."
              isCenter={true}
            >
              Résultat de la recherche
            </Heading>
            <div
              className={`grid md:grid-cols-4 sm:grid-cols-3 grid-cols-1 gap-6 md:gap-8`}
            >
              {/* If Course is Selected */}
              {courseDetails.length === 0 ? (
                <div className="col-span-full text-center text-neutral-500 dark:text-neutral-400">
                  Aucun cours correspondant à vos critères. Modifiez votre
                  recherche. 🤕
                </div>
              ) : (
                courseDetails
                  .slice(0, displayCount)
                  .sort((a, b) => b.averageCourseRating - a.averageCourseRating)
                  .map((course) => (
                    <div key={course.id}>
                      <Link
                        to={`/details/${course.id}`}
                        className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow ${className}`}
                        data-nc-id="CardAuthorBox2"
                      >
                        <div className="relative flex-shrink-0 ">
                          <div>
                            <NcImage
                              containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
                              src={`${course.teacher?.backgroundPhoto}`}
                            />
                          </div>
                          <div className="absolute top-3 inset-x-3 flex">
                            <div className="py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
                              {convertNumbThousand(course.averageCourseRating)}{" "}
                              <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" />
                            </div>
                          </div>
                        </div>
                        <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                          <svg
                            className="h-12 text-white dark:text-neutral-900 dark:group-hover:text-neutral-800"
                            viewBox="0 0 135 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M101.911 19.8581C99.4421 17.4194 97.15 14.8065 94.6816 12.1935C94.3289 11.671 93.8 11.3226 93.271 10.8C92.9184 10.4516 92.7421 10.2774 92.3895 9.92903C85.8658 3.83226 76.8737 0 67.1763 0C57.4789 0 48.4868 3.83226 41.7868 9.92903C41.4342 10.2774 41.2579 10.4516 40.9053 10.8C40.3763 11.3226 40.0237 11.671 39.4947 12.1935C37.0263 14.8065 34.7342 17.4194 32.2658 19.8581C23.45 28.7419 11.6368 30.4839 0 30.8323V54H16.5737H32.2658H101.734H110.374H134.176V30.6581C122.539 30.3097 110.726 28.7419 101.911 19.8581Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span className="absolute top-2">
                            <Avatar
                              containerClassName=""
                              sizeClass="w-12 h-12 text-2xl"
                              radius="rounded-full"
                              imgUrl={`${
                                course?.teacher?.photos.length !== 0
                                  ? `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${course?.teacher?.photos[0]?.s3Url}`
                                  : ""
                              }`}
                              userName={course?.teacher?.firstName?.charAt(0)}
                            />
                          </span>
                          <div className="mt-6">
                            <h2 className={`text-base font-medium`}>
                              <span className="line-clamp-1">
                                {course?.teacher?.firstName}
                              </span>
                            </h2>
                            <span
                              className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
                            >
                              @{course?.teacher?.role}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
              )}
              {/* If Teacher Name Selected */}
              {teacherData
                .slice(0, displayCount)
                .sort((a, b) => b.averageTeacherRating - a.averageTeacherRating)
                .map((teacher) => (
                  <div key={teacher.id}>
                    <Link
                      to={`/teacher`}
                      className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow ${className}`}
                      data-nc-id="CardAuthorBox2"
                      state={{ teacherId: teacher.id }}
                    >
                      <div className="relative flex-shrink-0 ">
                        <div>
                          <NcImage
                            containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
                            src={`${teacher?.backgroundPhoto}`}
                          />
                        </div>
                        <div className="absolute top-3 inset-x-3 flex">
                          <div className="py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
                            {convertNumbThousand(teacher.averageTeacherRating)}{" "}
                            <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" />
                          </div>
                        </div>
                      </div>
                      <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
                        <svg
                          className="h-12 text-white dark:text-neutral-900 dark:group-hover:text-neutral-800"
                          viewBox="0 0 135 54"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M101.911 19.8581C99.4421 17.4194 97.15 14.8065 94.6816 12.1935C94.3289 11.671 93.8 11.3226 93.271 10.8C92.9184 10.4516 92.7421 10.2774 92.3895 9.92903C85.8658 3.83226 76.8737 0 67.1763 0C57.4789 0 48.4868 3.83226 41.7868 9.92903C41.4342 10.2774 41.2579 10.4516 40.9053 10.8C40.3763 11.3226 40.0237 11.671 39.4947 12.1935C37.0263 14.8065 34.7342 17.4194 32.2658 19.8581C23.45 28.7419 11.6368 30.4839 0 30.8323V54H16.5737H32.2658H101.734H110.374H134.176V30.6581C122.539 30.3097 110.726 28.7419 101.911 19.8581Z"
                            fill="currentColor"
                          />
                        </svg>
                        <span className="absolute top-2">
                          <Avatar
                            containerClassName=""
                            sizeClass="w-12 h-12 text-2xl"
                            radius="rounded-full"
                            imgUrl={`${
                              teacher?.photos.length !== 0
                                ? `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${teacher?.photos[0]?.s3Url}`
                                : ""
                            }`}
                            userName={teacher?.firstName.charAt(0)}
                          />
                        </span>
                        <div className="mt-6">
                          <h2 className={`text-base font-medium`}>
                            <span className="line-clamp-1">
                              {teacher.firstName}
                            </span>
                          </h2>
                          <span
                            className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
                          >
                            @{teacher.role}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
            <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
              <ButtonSecondary href={"/"}>Revenir à l'accueil</ButtonSecondary>
              <ButtonPrimary href={"/signup/teacher"}>
                Devenir professeur
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingPage

import { StarIcon } from "@heroicons/react/24/solid"
import { PhotosType } from "data/types"
import React, { FC } from "react"
import Avatar from "shared/Avatar/Avatar"

interface CommentListingProps {
  className?: string
  hasListingTitle?: boolean
  name: string
  date: number | string
  comment: string
  startPoint: number
  avatar: string
  subscriptionType: string
}

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  hasListingTitle,
  name,
  date,
  comment,
  startPoint,
  avatar,
  subscriptionType
}) => {
  const RenderRattings = () => {
    let stars = [] // Create an array to accumulate StarIcon components

    for (let i = 0; i < startPoint; i++) {
      stars.push(<StarIcon key={i} className="w-4 h-4" />) // Push StarIcon components into the array
    }

    return stars // Return the array of StarIcon components
  }

  const formateDate = (timestamp: number) => {
    const date = new Date(timestamp)

    // Extract the date components (year, month, day, etc.)
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // Months are zero-based, so we add 1
    const day = date.getDate()
    let hours = date.getHours()
    const minutes = date.getMinutes()

    // Determine if it's AM or PM
    const ampm = hours >= 12 ? "PM" : "AM"

    // Convert to 12-hour format
    hours = hours % 12
    hours = hours ? hours : 12 // 12-hour clock, so 0 should be 12

    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`
  }

  return (
    <div
      className={`nc-CommentListing flex space-x-4 ${className}`}
      data-nc-id="CommentListing"
    >
      <div className="pt-0.5">
        <Avatar
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          userName={name.charAt(0)}
          imgUrl={`${
            avatar
              ? `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${avatar}`
              : ""
          }`}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold flex gap-4">
              <span className="text-base">{name}</span>
              <span
                className={`nc-Badge ${
                  subscriptionType === "SILVER"
                    ? "bg-[#E5E3E0]"
                    : subscriptionType === "GOLD"
                    ? "bg-[#DAA511] text-white"
                    : "bg-[#A8A9AD]"
                } inline-flex px-2.5 py-0.5 rounded-full font-medium text-[10px] dark:text-black `}
              >
                {subscriptionType}
              </span>
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-1">
              {formateDate(Number(date))}
            </span>
          </div>
          <div className="flex text-yellow-500">
            <RenderRattings />
          </div>
        </div>

        <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
          {comment}
        </span>
      </div>
    </div>
  )
}

export default CommentListing

import React, { FC, useEffect, useState } from "react"
import { useAuth } from "service/Auth"
import { ApiService } from "service/ApiService"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import NcImage from "shared/NcImage/NcImage"
import StartRating from "components/StartRating/StartRating"
import { TeacherDataTypes } from "data/types"

export interface PaymentConfirmationPageProps {
  className?: string
}

const PaymentConfirmationPage: FC<PaymentConfirmationPageProps> = ({
  className = ""
}) => {
  const { user } = useAuth()
  const [teacher, setTeacher] = useState<TeacherDataTypes | null>(null)

  useEffect(() => {
    // Replace with actual logic to get the teacher ID
    const teacherId = 1 // Assuming you have a way to get the teacher ID

    ApiService.getTeacherById(teacherId)
      .then((res) => {
        setTeacher(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Votre demande a été envoyée
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">
            Nous avons bien reçu votre demande de paiement par transfert
            d'argent.
          </h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage
                  src={`https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${teacher?.photos[0].s3Url}`}
                />
              </div>
            </div>
            <div className="pt-5 sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {teacher?.role}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {teacher
                    ? `${teacher.firstName} ${teacher.lastName}`
                    : "Nom non disponible"}
                </span>
              </div>
              <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                {teacher?.email}
              </span>
              <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
              <StartRating
                reviewCount={teacher?.totalTeacherReviews || 0}
                point={teacher?.averageTeacherRating || 0}
              />
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">
                  Nous reviendrons vers vous une fois le paiement validé.
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  Merci de votre patience.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href="/">Aller au Dashboard 🥳</ButtonPrimary>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`nc-PaymentConfirmationPage ${className}`}
      data-nc-id="PaymentConfirmationPage"
    >
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  )
}

export default PaymentConfirmationPage

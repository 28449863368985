import React, { useState, FC } from "react"
import { Helmet } from "react-helmet"
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2"
import SocialsList from "shared/SocialsList/SocialsList"
import Label from "components/Label/Label"
import Input from "shared/Input/Input"
import Textarea from "shared/Textarea/Textarea"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import SectionClientSay from "components/SectionClientSay/SectionClientSay"
import BackgroundSection from "components/BackgroundSection/BackgroundSection"
import { ApiService } from "service/ApiService"
import { ContactFormData } from "data/types"
export interface PageContactProps {
  className?: string
}

const info = [
  {
    title: "💌 EMAIL",
    desc: "contact@coursplusplus.com"
  }
]

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [formData, setFormData] = useState<ContactFormData>({
    nom: "",
    email: "",
    telephone: "",
    message: ""
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    setErrorMessage("")
    setSuccessMessage("")

    try {
      await ApiService.sendContactForm(formData)
      setSuccessMessage(
        "Merci pour votre message ! 😊 Nous vous répondrons très vite. 😉"
      )
    } catch (error) {
      setErrorMessage("Erreur lors de l'envoi du message.")
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Cours PlusPlus || Personalized Academic Support</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Nous sommes à votre écoute 🤗
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <Label>
                    Nom <span className="text-red-500">*</span>
                  </Label>
                  <Input
                    name="nom"
                    placeholder="Votre nom"
                    type="text"
                    className="mt-1"
                    value={formData.nom}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="block">
                  <Label>
                    Email <span className="text-red-500">*</span>
                  </Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Votre email"
                    className="mt-1"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="block">
                  <Label>Numéro de téléphone</Label>
                  <Input
                    name="telephone"
                    placeholder="Votre numéro de téléphone"
                    type="tel"
                    className="mt-1"
                    value={formData.telephone}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>
                    Message <span className="text-red-500">*</span>
                  </Label>
                  <Textarea
                    name="message"
                    className="mt-1"
                    rows={6}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Envoi en cours..." : "Envoyer 📨"}
                  </ButtonPrimary>
                </div>
              </form>
              {errorMessage && (
                <p className="text-red-500 mt-4">{errorMessage}</p>
              )}
              {successMessage && (
                <p className="text-green-500 mt-4">{successMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  )
}

export default PageContact

import React, { FC, useEffect, useState, Fragment } from "react"
import visaPng from "images/vis.png"
import mastercardPng from "images/mastercard.svg"
import StartRating from "components/StartRating/StartRating"
import NcModal from "shared/NcModal/NcModal"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import { ApiService } from "service/ApiService"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "service/Auth"
import { CourseDetailPageTypes } from "data/types"
import { Tab } from "@headlessui/react"
import { log } from "console"

export interface CheckOutPagePageMainProps {
  className?: string
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = ""
}) => {
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [courseDetails, setCourseDetails] = useState<CourseDetailPageTypes>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formErr, setFormErr] = useState<string | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>("STRIPE")
  const [file, setFile] = useState<File | null>(null)

  const { state } = location

  useEffect(() => {
    if (!user && !state) {
      navigate("/")
    }
  }, [user])

  let group_id: number = 0
  let course_id: number = 0
  let package_name: string = ""

  if (state) {
    group_id = state.group_id || 0
    course_id = state.course_id || 0
    package_name = state.package_name || ""
  }

  useEffect(() => {
    ApiService.getCourseById(Number(course_id))
      .then((res) => {
        setCourseDetails(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const handleStripePaymant = () => {
    if (user) {
      setIsLoading(true)
      ApiService.enrollmentsStudent(user.token, user.id, group_id)
        .then((res) => {
          setIsLoading(false)

          if (res.data.status !== "FAIL") {
            const link = res.data.data.stripeInfo.stripeCheckoutUrl

            window.location.href = link
            setFormErr(null)
          } else {
            setFormErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          setFormErr(err.message)
          setIsLoading(false)
        })
    }
  }

  // Function to convert month number to abbreviated month name
  const getAbbreviatedMonthName = (month: number): string => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]
    return months[month - 1]
  }

  // Function to convert date object to specified format
  const getFrenchMonthName = (monthIndex: number): string => {
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre"
    ]
    return months[monthIndex]
  }

  const formatDateRange = (endDate: number[]): string => {
    const endMonth = getFrenchMonthName(endDate[1] - 1) // Mois à l'index 1, ajuster car les mois sont de 0 à 11

    return `${endMonth} ${endDate[2].toString().padStart(2, "0")} ${endDate[0]}` // Jour à l'index 2, année à l'index 0
  }

  const formatTime = (time: number[]) => {
    const [hour, minute] = time

    // Add leading zero to hours and minutes if needed
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`

    return `${formattedHour}:${formattedMinute}`
  }

  const handleStripePayment = () => {
    if (user) {
      setIsLoading(true)
      ApiService.enrollmentsStudent(user.token, user.id, group_id)
        .then((res) => {
          setIsLoading(false)
          if (res.data.status !== "FAIL") {
            const link = res.data.data.paymentInfo.stripeInfo.stripeCheckoutUrl

            window.location.href = link
            setFormErr(null)
          } else {
            setFormErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          setFormErr(err.message)
          setIsLoading(false)
        })
    }
  }

  const handleWireTransferPayment = () => {
    if (user && file) {
      setIsLoading(true)
      ApiService.enrollmentsStudentWireTransfer(
        user.token,
        user.id,
        group_id,
        file
      )
        .then((res) => {
          setIsLoading(false)

          if (res.data.status !== "FAIL") {
            navigate("/payment-confirmation") // Redirection vers la page de confirmation
            setFormErr(null)
          } else {
            setFormErr(res.data.error)
          }
        })
        .catch((err) => {
          console.log(err)
          setFormErr(err.message)
          setIsLoading(false)
        })
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0])
    }
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:items-left">
          <div className="flex-shrink-0 w-full">
            <h1 className="text-3xl font-medium">
              Abonnement : {package_name}
              {" ✨ "}
            </h1>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {courseDetails?.description}
              </span>
              <span className="text-base font-medium mt-1 block">
                {courseDetails?.title}
              </span>
            </div>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating
              point={courseDetails?.averageCourseRating}
              reviewCount={courseDetails?.totalCourseReviews}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Prix</span>
            <span>
              {courseDetails?.groups.map((grp, idx) => {
                if (grp.id === group_id) {
                  return `${grp.subscription.price}dh`
                }
              })}
              /mois
            </span>
          </div>
        </div>
      </div>
    )
  }

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirmation et paiement
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold pb-2">Vos séances : </h3>
            <div className="text-sm text-neutral-400 flex gap-2 pb-2">
              <span>
                Vous pouvez annuler votre abonnement à tout moment, sans
                engagement 😇.
              </span>
            </div>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  Voir les détails de votre cours
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Details de votre cours"
            />
          </div>
          <div className="mt-6 border  border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col  divide-y    divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10 p-4">
            {courseDetails?.groups.map((grp, idx) => {
              if (grp.id === group_id) {
                return grp.slots.map((slt, idx) => (
                  <div key={idx} className="flex items-center gap-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </div>
                    <div className={`flex flex-col gap-1  `}>
                      <div className="text-sm dark:text-white flex gap-2 pt-2">
                        <div className="capitalize text-lowercase flex flex-col w-[90px]">
                          Tous les :{" "}
                          {slt.recurrenceDaysInFrench.map((day, idx) => (
                            <span key={idx}>
                              {day}
                              {idx + 1 === slt.recurrenceDaysInFrench.length
                                ? ""
                                : ","}
                            </span>
                          ))}
                        </div>
                        <span>
                          {formatTime(slt.startTime) +
                            "-" +
                            formatTime(slt.endTime)}
                        </span>
                      </div>

                      <div className="text-sm text-neutral-400 flex gap-2 pb-2">
                        <span className="w-[90px]">Jusqu'à</span>
                        <span>{formatDateRange(slt.endDate)}</span>
                      </div>
                    </div>
                  </div>
                ))
              }
            })}
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">🔐 Paiement 100% sécurisé</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
          <span className="text-red-500 text-lg">{formErr}</span>
          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-600 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="flex items-center">
                        <img
                          className="w-9 h-3 mr-0"
                          src={visaPng}
                          alt="Visa"
                        />
                        <img
                          className="w-9 h-8 mr-0"
                          src={mastercardPng}
                          alt="Mastercard"
                        />
                        Carte bancaire
                      </span>
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-600 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Virement bancaire
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  {!isLoading ? (
                    <ButtonPrimary
                      className="!bg-transparent border-neutral-200 dark:border-neutral-700 hover:shadow-lg border"
                      onClick={handleStripePayment}
                    >
                      <span className="mr-2.5 text-black dark:text-white">
                        Confirmer le paiement par carte 💳
                      </span>
                    </ButtonPrimary>
                  ) : (
                    <span className="nc-Button text-black dark:text-white relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-600 hover:bg-primary-700 !bg-transparent border-neutral-200 dark:border-neutral-700 hover:shadow-lg border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 dark:focus:ring-offset-0">
                      Chargement...
                    </span>
                  )}
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <div>
                      <span className="text-lg font-semibold">
                        Justificatif de paiement
                      </span>
                      <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                        Merci de transférer le justificatif de paiement après
                        l'envoi d'argent.
                      </p>

                      <div className="mt-4">
                        <span className="text-lg font-semibold">
                          Coordonnées bancaires pour le virement :
                        </span>
                        <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                          Titulaire du compte : [Nom du titulaire]
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          IBAN : [Votre IBAN]
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          BIC/SWIFT : [Votre BIC/SWIFT]
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          Banque : [Nom de la banque]
                        </p>
                      </div>

                      <div className="mt-4">
                        <span className="text-lg font-semibold">
                          Envoi d'argent via MoneyGram, Western Union, etc. :
                        </span>
                        <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                          Nom du bénéficiaire : [Nom complet du bénéficiaire]
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          Adresse : [Adresse du bénéficiaire]
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          Pays : [Pays du bénéficiaire]
                        </p>
                        <p className="text-sm text-neutral-600 dark:text-neutral-300">
                          Numéro de téléphone : [Numéro de téléphone du
                          bénéficiaire]
                        </p>
                        <p className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                          Veuillez utiliser les services suivants : MoneyGram,
                          Western Union, Ria, etc.
                        </p>
                      </div>

                      <div className="mt-5">
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-neutral-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <div className="flex text-sm text-neutral-600 dark:text-neutral-300">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                              >
                                <span>Télécharger un fichier</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  onChange={handleFileChange}
                                />
                              </label>
                              <p className="pl-1">ou glisser-déposer</p>
                            </div>
                            <p className="text-xs text-neutral-500 dark:text-neutral-400">
                              PNG, JPG, GIF jusqu'à 10MB
                            </p>
                          </div>
                        </div>
                        <div className="pt-8">
                          <ButtonPrimary onClick={handleWireTransferPayment}>
                            Confirmer et s'inscrire
                          </ButtonPrimary>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="lg:block flex-grow lg:w-2/3 xl:w-2/3">
          {renderMain()}
        </div>
        <div className="w-full lg:w-1/3 xl:w-1/3 lg:pl-10 lg:block hidden">
          {renderSidebar()}
        </div>
      </main>
    </div>
  )
}

export default CheckOutPagePageMain

import React, { FC, useEffect, useState } from "react";
import TeacherNameInput from "../TeacherNameInput";
import { ApiService } from "service/ApiService";
import { TeacherDataTypes } from "data/types";

export interface TeacherSearchFormProps {}

const TeacherSearchForm: FC<TeacherSearchFormProps> = () => {
  const [allTeachers, setAllTeachers] = useState<TeacherDataTypes[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<string>("");
  const [selectedTeacherId, setSelectedTeacherId] = useState<number>(0);

  useEffect(() => {
    ApiService.getAllTeachers()
      .then((res) => setAllTeachers(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col md:flex-row  rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <TeacherNameInput
          buttonIsDisabled={selectedTeacher ? false : true}
          buttonStateData={{
            teacherId: selectedTeacherId,
          }}
          data={allTeachers}
          className="flex-[1.5]"
          value={selectedTeacher}
          onChange={(value: string) => {
            setSelectedTeacher(value);
            allTeachers.map((teacher) => {
              if (value === teacher.firstName) {
                setSelectedTeacherId(teacher.id);
              }
            });
          }}
          buttonSubmitHref="/search"
        />
      </form>
    );
  };

  return renderForm();
};

export default TeacherSearchForm;

import React, { FC } from "react";
import { SubjectTypes, TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategoryBox1Props {
  className?: string;
  taxonomy: SubjectTypes;
}

const CardCategoryBox1: FC<CardCategoryBox1Props> = ({
  className = "",
  taxonomy,
}) => {
  const { name, id } = taxonomy;
  return (
    <Link
      to={"/search"}
      className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardCategoryBox1"
      state={{ subjectId: id }}
    >
      <Badge
        className="absolute right-2 top-2"
        color="gray"
        name={convertNumbThousand(100)}
      />

      <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
        <NcImage src={""} containerClassName="absolute inset-0" />
      </div>
      <div className="ml-4 flex-grow overflow-hidden">
        <h2 className="text-base font-medium">
          <span className="line-clamp-1">{name}</span>
        </h2>
      </div>
    </Link>
  );
};

export default CardCategoryBox1;

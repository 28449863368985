"use client"
import {
  AcademicCapIcon,
  BookOpenIcon,
  ClockIcon,
  MapPinIcon
} from "@heroicons/react/24/outline"
import React, { useState, useRef, useEffect, FC, ChangeEvent } from "react"
import ClearDataButton from "./ClearDataButton"
import { SubjectTypes } from "data/types"
import ButtonSubmit from "./ButtonSubmit"

export interface LocationInputProps {
  placeHolder?: string
  desc?: string
  className?: string
  divHideVerticalLineClass?: string
  autoFocus?: boolean
  onChange: (value: string) => void
  value: string
  data: SubjectTypes[]
}

const SubjectsInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Matière",
  desc = "Selectionnez une matière",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onChange,
  value,
  data
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [showPopover, setShowPopover] = useState(autoFocus)

  useEffect(() => {
    setShowPopover(autoFocus)
  }, [autoFocus])

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv)
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv)
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv)
    }
  }, [showPopover])

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus()
    }
  }, [showPopover])

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return
    }
    // CLICK OUT_SIDE
    setShowPopover(false)
  }

  const handleSelectLocation = (item: string) => {
    onChange(item)
    setShowPopover(false)
  }

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Suggestions
        </h3>
        <div className="mt-2">
          {data?.map((item) => (
            <span
              onClick={() => handleSelectLocation(String(item.name))}
              key={Number(item.id)}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))}
        </div>
      </>
    )
  }

  const renderSearchValue = () => {
    return (
      <>
        {data
          ?.filter((catName) =>
            catName.name.toLowerCase().includes(value.toLowerCase())
          )
          .map((item) => (
            <span
              onClick={() => handleSelectLocation(String(item.name))}
              key={Number(item.id)}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))}
      </>
    )
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange(value)
  }
  return (
    <div
      className={`relative flex items-center ${className} ${
        showPopover ? "nc-hero-field-focused" : ""
      }`}
      ref={containerRef}
    >
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left `}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <BookOpenIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={handleChange}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                onChange("")
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 w-fit ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <>
          <div className="absolute right-0 z-40 w-full sm:min-w-[380px] max-w-md bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
            {value ? renderSearchValue() : renderRecentSearches()}
          </div>
        </>
      )}
    </div>
  )
}

export default SubjectsInput

import React, {
  ChangeEvent,
  FC,
  FormEvent,
  Fragment,
  useEffect,
  useRef,
  useState
} from "react"
import CommentListing from "components/CommentListing/CommentListing"
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate"
import StartRating from "components/StartRating/StartRating"
import Avatar from "shared/Avatar/Avatar"
import Badge from "shared/Badge/Badge"
import LikeSaveBtns from "components/LikeSaveBtns"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import { ArrowRightIcon, CheckIcon } from "@heroicons/react/24/outline"
import ButtonCircle from "shared/Button/ButtonCircle"
import Input from "shared/Input/Input"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import DetailPagetLayout from "../Layout"
import { ApiService } from "service/ApiService"
import {
  CourseDetailPageTypes,
  RegisterFormTypes,
  SpecialtiesTypes,
  StudyLevelsTypes,
  TeacherDataTypes,
  loginFormData
} from "data/types"
import { FaCheckCircle } from "react-icons/fa"
import { useAuth } from "service/Auth"
import Select from "shared/Select/Select"
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2"
import { read } from "fs"
import swal from "sweetalert"

const StayDetailPageContainer: FC<{}> = () => {
  const { courseId } = useParams()
  const router = useNavigate()
  // Auth Data
  const { user, setSession } = useAuth()
  const userAuthFormRef = useRef<HTMLDivElement | null>(null)
  const confirmPasswordErrRef = useRef<HTMLDivElement | null>(null)

  const [courseDetails, setCourseDetails] = useState<CourseDetailPageTypes>()
  const [teacherDetails, setTeacherDetails] = useState<TeacherDataTypes>()
  const [selectedCheck, setSelectedCheck] = useState<number>(0)
  const [selectedPackage, setSelectedPackage] = useState<string>("")
  const [openAuthForm, setOpenAuthForm] = useState<string>("")
  // Add Review Api
  const [addReview, setAddReview] = useState<string>("")
  const [loginModal, setLoginModal] = useState<boolean>(false)
  const [reviewStars, setReviewStars] = useState<number>(0)
  const ratingErr = useRef<HTMLSpanElement | null>(null)
  const [rattingResErr, setRattingResErr] = useState<string>("")
  const [rattingResSuccess, setRattingResSuccess] = useState<string>("")
  //
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isFormInProcess, setIsFormInProcess] = useState(false)
  const [isPasswordGood, setIsPasswordGood] = useState(false)
  const [isAuthForm, setIsAuthForm] = useState<boolean>(false)
  const passwordRef = useRef(null)
  const passwordErrRef = useRef(null)
  // Auth Dropdowns
  const [studyLevels, setStudyLevels] = useState<StudyLevelsTypes[]>([])
  const [Specialties, setSpecialties] = useState<SpecialtiesTypes[]>([])
  //
  const [packages, setPackages] = useState<[string, string, string]>([
    "SILVER",
    "GOLD",
    "PLATINUM"
  ])

  type PackType = "SILVER" | "GOLD" | "PLATINUM"

  // Login
  const [userData, setUserData] = useState<loginFormData>({
    email: "",
    password: ""
  })
  // SignUp
  const [signUpUserForm, setSignUpUserForm] = useState<RegisterFormTypes>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: 0,
    address: "",
    city: "",
    studyLevelId: 0,
    specialtyId: 0,
    photos: null,
    gender: "",
    birthDate: "",
    aboutMe: ""
  })

  // useEffects
  useEffect(() => {
    ApiService.getAllStudyLevels()
      .then((res) => {
        setStudyLevels(res.data.data)
      })
      .catch((err) => console.log(err))

    ApiService.getCourseById(Number(courseId))
      .then((res) => {
        setCourseDetails(res.data.data)

        const { id } = res.data.data.teacher
        ApiService.getTeacherById(id)
          .then((res) => {
            setTeacherDetails(res.data.data)
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => console.log(err))
  }, [])

  // All Methods
  function closeLoginModal() {
    setLoginModal(false)
  }
  function openLoginModal() {
    setLoginModal(true)
  }

  type TwMainColor = "gray" | "yellow" | "black"

  const getColorForPack = (pack: string): TwMainColor => {
    switch (pack) {
      case "SILVER":
        return "gray"
      case "GOLD":
        return "yellow"
      case "PLATINUM":
        return "black"
      default:
        return "gray"
    }
  }

  const handleRatingSelection = (stars: number) => {
    setReviewStars(stars)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (value) {
      setSignUpUserForm({ ...signUpUserForm, [name]: value })
    }
  }

  const [loginErr, setLoginErr] = useState("")

  const handleLoginSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (userData.email || userData.password) {
      setIsFormInProcess(true)
      ApiService.login(userData.email.toLowerCase(), userData.password)
        .then((res: any) => {
          setIsFormInProcess(false)

          if (res.data.status === "FAIL") {
            setLoginErr(res.data.details)
          } else {
            setSession(res.data.data)
            setIsSubmiting(false)
            const states = {
              group_id: selectedCheck,
              package_name: selectedPackage,
              course_id: courseId
            }
            router("/checkout", { state: states })
          }
        })
        .catch((err: any) => {
          console.error(err)
          setIsFormInProcess(false)
        })
    }
  }

  const [signupErr, setSignupErr] = useState("")

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    // Check Password size
    if (!isPasswordGood) {
      setSignupErr("Le mot de passe doit être le même !")
      return
    } else {
      setSignupErr("")
    }

    if (signUpUserForm.password !== signUpUserForm.confirmPassword) {
      if (confirmPasswordErrRef.current) {
        confirmPasswordErrRef.current.style.display = "block"
        return
      }
    } else {
      if (confirmPasswordErrRef.current) {
        confirmPasswordErrRef.current.style.display = "none"
      }
    }

    // Disabled the submit button during submit
    setIsSubmiting(true)

    // axios post method
    ApiService.signupStudent(
      signUpUserForm.firstName,
      signUpUserForm.lastName,
      signUpUserForm.email,
      signUpUserForm.password,
      signUpUserForm.phone,
      signUpUserForm.address,
      signUpUserForm.city,
      signUpUserForm.photos,
      signUpUserForm.studyLevelId,
      signUpUserForm.specialtyId,
      signUpUserForm.birthDate,
      signUpUserForm.gender
    )
      .then((result: any) => {
        console.log(result.data)
        setIsSubmiting(false)
        if (result.data.status === "SUCCESS") {
          swal({
            title: "Felicitations!",
            text: "Bienvenue à bord ! Votre compte a été créé avec succès.",
            icon: "success",
            buttons: {
              continue: {
                text: "Continuer",
                value: true
              }
            }
          })
          setTimeout(() => {
            if (userAuthFormRef.current) {
              const scrollPosition =
                userAuthFormRef.current.getBoundingClientRect().top - 150

              window.scrollBy({
                top: scrollPosition,
                behavior: "smooth"
              })
            }
          }, 0)

          setOpenAuthForm("login")
        } else {
          setSignupErr(result.data.details)
        }
      })
      .catch((err: any) => console.warn(err))
  }

  const handlePasswordCharacters = () => {
    if (signUpUserForm.password) {
      const password = signUpUserForm.password.length
      if (passwordRef.current && passwordErrRef.current) {
        const passwordInput = passwordRef.current as HTMLInputElement
        const passwordErrSpan = passwordErrRef.current as HTMLElement
        if (password < 6 || password > 20) {
          passwordInput.style.border = "1px solid red"
          passwordErrSpan.style.display = "block"
          setIsPasswordGood(false)
        } else {
          passwordInput.style.border = ""
          passwordErrSpan.style.display = ""
          setIsPasswordGood(true)
        }
      }
    }
  }

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      setSignUpUserForm({ ...signUpUserForm, photos: file })
    }
  }

  const handleStudyLevelSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target

    studyLevels.find((std) =>
      std.id === Number(value) ? setSpecialties(std.specialties) : ""
    )

    setSignUpUserForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target

    setSignUpUserForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleCheck = (grpId: number, pack: string) => {
    setSelectedCheck(grpId)
    setSelectedPackage(pack)
  }

  const handlePackageSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    pack: string
  ) => {
    e.preventDefault()

    const states = {
      group_id: selectedCheck,
      package_name: selectedPackage,
      course_id: courseId
    }

    if (!user) {
      setIsAuthForm(true)
      setTimeout(() => {
        if (userAuthFormRef.current) {
          const scrollPosition =
            userAuthFormRef.current.getBoundingClientRect().top - 150

          window.scrollBy({
            top: scrollPosition,
            behavior: "smooth"
          })
        }
      }, 0)
      return
    } else {
      router("/checkout", { state: states })
    }
  }

  const getFrenchMonthName = (monthIndex: number): string => {
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre"
    ]
    return months[monthIndex]
  }

  const formatDateRange = (endDate: number[]): string => {
    const endMonth = getFrenchMonthName(endDate[1] - 1) // Mois à l'index 1, ajuster car les mois sont de 0 à 11

    return `${endMonth} ${endDate[0]}` // Jour à l'index 2, année à l'index 0
  }

  const formatTime = (time: number[]) => {
    const [hour, minute] = time

    // Add leading zero to hours and minutes if needed
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`

    return `${formattedHour}:${formattedMinute}`
  }

  // render screens
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6 pt-4 pb-10">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge name="Wooden house" />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {courseDetails?.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating
            point={courseDetails?.averageCourseRating}
            reviewCount={courseDetails?.totalCourseReviews}
          />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{courseDetails?.teacher.city}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar
            hasChecked
            sizeClass="h-10 w-10"
            radius="rounded-full"
            imgUrl={`${
              teacherDetails?.photos.length !== 0
                ? `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${teacherDetails?.photos[0]?.s3Url}`
                : ""
            }`}
            userName={teacherDetails?.firstName.charAt(0)}
          />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Professeur :{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {`${teacherDetails?.firstName} ${teacherDetails?.lastName}`}
            </span>
          </span>
        </div>
      </div>
    )
  }

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description du cours</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{courseDetails?.description}</span>
        </div>
      </div>
    )
  }

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Filières</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` Ce cours peut être enseigné dans les filières suivantes :`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="flex flex-col gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {courseDetails?.specialties.map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                />
              </svg>

              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderSlots = () => {
    return (
      <div className="listingSection__wrap">
        <div
          className={`nc-PageSubcription container pb-14 lg:pb-10`}
          data-nc-id="PageSubcription"
        >
          <header className="text-center max-w-2xl mx-auto my-20">
            <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Créneaux disponibles
              <span className="mr-4 text-3xl md:text-4xl leading-none">
                {" "}
                💎
              </span>
            </h2>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              Programmez votre apprentissage avec régularité et efficacité 🚀
            </span>
          </header>
          <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
            <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
              {packages.map((pack: string, idx) => (
                <div key={idx}>
                  <form onSubmit={(e) => handlePackageSubmit(e, pack)}>
                    <div
                      key={idx}
                      className={`h-full relative px-6 py-8 rounded-3xl border-2 border-neutral-200 dark:border-neutral-700 flex flex-col overflow-hidden `}
                    >
                      <Badge
                        href="##"
                        color={getColorForPack(pack)} // Assurez-vous que cette fonction retourne 'pink', 'red', 'gray', etc.
                        name={pack}
                        className=" px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10" // Ajoutez une classe personnalisée si nécessaire
                      />

                      <div className="mb-8">
                        <h2 className="text-5xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
                          <span>
                            {
                              courseDetails?.groups.find(
                                (e) =>
                                  e.subscription.type === pack &&
                                  e.subscription.price
                              )?.subscription.price
                            }
                          </span>
                          <span className="text-lg ml-1 font-normal text-neutral-500">
                            Dh/mois
                          </span>
                        </h2>
                      </div>

                      <nav className="space-y-4 mb-8">
                        <li className="flex items-center">
                          <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                          <span className="text-neutral-700 dark:text-neutral-300">
                            Max d'étudiants : &nbsp;
                            <strong>
                              {courseDetails?.groups.find(
                                (e) =>
                                  e.subscription.type === pack &&
                                  e.subscription.maxStudents
                              )?.subscription.maxStudents || "0"}
                            </strong>
                          </span>
                        </li>
                        <li className="flex items-center">
                          <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                          <span className="text-neutral-700 dark:text-neutral-300">
                            Nombre de places restantes : &nbsp;
                            <strong>
                              {courseDetails?.groups.find(
                                (e) =>
                                  e.subscription.type === pack &&
                                  e.remainingSeats
                              )?.remainingSeats || "0"}
                            </strong>
                          </span>
                        </li>
                        <li className="flex items-center">
                          <span
                            className={`mr-4 inline-flex flex-shrink-0 ${
                              courseDetails?.groups.find(
                                (e) =>
                                  e.subscription.type === pack &&
                                  e.remainingSeats > 0
                              )
                                ? "text-yellow-500"
                                : "text-red-500"
                            }`}
                          >
                            {courseDetails?.groups.find(
                              (e) =>
                                e.subscription.type === pack &&
                                e.remainingSeats > 0
                            ) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 3l2.3 6.9h7.2l-5.8 4.2 2.3 6.9L12 16.8 6.9 21l2.3-6.9L3.4 9.9h7.2z"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            )}
                          </span>

                          <span
                            className={`text-neutral-700 dark:text-neutral-300`}
                          >
                            {courseDetails?.groups.find(
                              (e) =>
                                e.subscription.type === pack &&
                                e.remainingSeats > 0
                            )
                              ? "Dépêchez-vous ! 🙈"
                              : "Oups, plus de places ! 😬"}
                          </span>
                        </li>
                      </nav>

                      <span className="text-base dark:text-white py-2">
                        Choisissez votre groupe
                      </span>
                      <div className="flex flex-col gap-2 mb-2">
                        {courseDetails?.groups.map((grp, idx) => {
                          if (grp.subscription.type === pack) {
                            const isDisabled = grp.remainingSeats === 0

                            return (
                              <label
                                key={idx}
                                className={`border relative border-neutral-200 dark:border-neutral-700 flex p-2 rounded-2xl gap-2 ${
                                  isDisabled
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                                onClick={() =>
                                  !isDisabled && handleCheck(grp.id, pack)
                                }
                              >
                                <div className="border-r pr-2 flex justify-center items-center">
                                  <div className="text-neutral-300 dark:text-neutral-400">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      aria-hidden="true"
                                      className="w-7 h-7 lg:w-7 lg:h-7"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                                      />
                                    </svg>
                                  </div>
                                </div>
                                <div className="flex-1 flex flex-col">
                                  {grp.slots.map((slt, indx) => (
                                    <div className={`flex flex-col`} key={indx}>
                                      <div className="manageScreen text-xs dark:text-white flex xs:flex-row ms:flex-col lg:flex-row gap-2 ">
                                        <div className="capitalize text-lowercase flex flex-col w-[75px]">
                                          {slt.recurrenceDaysInFrench.map(
                                            (day, idx) => (
                                              <span key={idx}>
                                                {day}
                                                {idx + 1 ===
                                                slt.recurrenceDaysInFrench
                                                  .length
                                                  ? ""
                                                  : ","}
                                              </span>
                                            )
                                          )}
                                        </div>
                                        <span>
                                          {formatTime(slt.startTime) +
                                            " - " +
                                            formatTime(slt.endTime)}
                                        </span>
                                      </div>

                                      {indx === grp.slots.length - 1 && (
                                        <div className="text-xs text-neutral-400 flex gap-2">
                                          <span className="w-[75px]">
                                            Jusqu'au
                                          </span>
                                          <span>
                                            {formatDateRange(slt.endDate)}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                                <div className="h-full flex  top-2 right-2">
                                  <span>
                                    {isDisabled ? (
                                      <div className="text-red-500 text-xs">
                                        Groupe complet
                                      </div>
                                    ) : selectedCheck === grp.id ? (
                                      <div className="w-5 h-5 rounded-full dark:bg-white flex justify-center items-center">
                                        <FaCheckCircle className="text-primary-6000 text-xl" />
                                      </div>
                                    ) : (
                                      <div className="w-5 h-5 rounded-full dark:bg-white flex justify-center items-center">
                                        <FaCheckCircle className="text-neutral-400 text-xl" />
                                      </div>
                                    )}
                                  </span>
                                </div>
                              </label>
                            )
                          }
                          return null // Si la condition ne correspond pas, retournez null
                        })}
                      </div>
                      <div className="flex flex-col mt-auto">
                        {selectedPackage === pack ? (
                          <ButtonPrimary>Choisir</ButtonPrimary>
                        ) : (
                          <ButtonPrimary className="!bg-gray-500 select-none cursor-not-allowed pointer-events-none">
                            Choisir
                          </ButtonPrimary>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              ))}
            </div>
          </section>
        </div>
        {/* Auth Form */}

        <div
          className={`listingSection__wrap w-full sm:py-5 sm:px-8 flex-col ${
            isAuthForm ? "flex" : "hidden"
          }`}
          ref={userAuthFormRef}
        >
          {openAuthForm === "login" ? (
            <div className="flex flex-col gap-6">
              {/* Feilds */}
              <form
                className="grid grid-cols-1 gap-6"
                action="#"
                onSubmit={handleLoginSubmit}
              >
                <div>
                  <div className="flex gap-4">
                    <label className="block w-full">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Email
                      </span>
                      <Input
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        required
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setUserData((prev) => {
                            return {
                              ...prev,
                              email: e.target.value
                            }
                          })
                        }
                      />
                    </label>
                    <label className="block w-full">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Mot de passe
                        <Link to="/forgot-pass" className="text-sm">
                          Mot de passe oublié ?
                        </Link>
                      </span>
                      <Input
                        type="password"
                        className="mt-1"
                        required
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setUserData((prev) => {
                            return {
                              ...prev,
                              password: e.target.value
                            }
                          })
                        }}
                      />
                    </label>
                  </div>
                  {loginErr && (
                    <span className="text-red-500 text-sm">{loginErr}</span>
                  )}
                </div>
                <ButtonPrimary
                  disabled={isFormInProcess ? true : false}
                  className={`w-full rounded-md${
                    isFormInProcess ? "cursor-not-allowed bg-slate-200" : ""
                  }`}
                  type="submit"
                >
                  Se connecter
                </ButtonPrimary>
              </form>
              {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              <ButtonPrimary
                className="w-full rounded-md"
                onClick={() => setOpenAuthForm("signup")}
              >
                Créer un compte
              </ButtonPrimary>
            </div>
          ) : openAuthForm === "signup" ? (
            <div className="flex flex-col gap-6">
              {/* Feilds */}
              <form
                className="grid grid-cols-1 gap-6"
                action="#"
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="grid grid-cols-2 gap-4">
                    {/* FirstName */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Nom *
                      </span>
                      <Input
                        required
                        type="text"
                        placeholder="Nom..."
                        className="mt-1"
                        name="firstName"
                        onChange={handleChange}
                      />
                    </label>
                    {/* Last Name */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Prénom*
                      </span>
                      <Input
                        required
                        type="text"
                        placeholder="Prénom..."
                        className="mt-1"
                        name="lastName"
                        onChange={handleChange}
                      />
                    </label>
                    {/* Email */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Email *
                      </span>
                      <Input
                        required
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        name="email"
                        onChange={handleChange}
                      />
                    </label>
                    {/* Adress */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Adresse*
                      </span>
                      <Input
                        required
                        type="text"
                        placeholder="Adresse"
                        className="mt-1"
                        name="address"
                        onChange={handleChange}
                      />
                    </label>
                    {/* City */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Ville*
                      </span>
                      <Input
                        required
                        type="text"
                        placeholder="Ville"
                        className="mt-1"
                        name="city"
                        onChange={handleChange}
                      />
                    </label>
                    {/*  */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Niveau d'étude*
                      </span>
                      <Select
                        name="studyLevelId"
                        className={`mt-1`}
                        onChange={handleStudyLevelSelect}
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {studyLevels.map((std, idx) => (
                          <option key={idx} value={std.id}>
                            {std.name}
                          </option>
                        ))}
                      </Select>
                    </label>
                    {/*  */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Filière*
                      </span>
                      <Select
                        name="specialtyId"
                        className={`mt-1`}
                        onChange={handleSelectChange}
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {Specialties.map((std, idx) => (
                          <option key={idx} value={std.id}>
                            {std.name}
                          </option>
                        ))}
                      </Select>
                    </label>
                    {/* Phone */}
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Tèlèphone*
                      </span>
                      <Input
                        required
                        type="number"
                        placeholder="01234567..."
                        className="mt-1"
                        name="phone"
                        onChange={handleChange}
                      />
                    </label>
                    {/* gender */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Sexe*
                      </span>
                      <Select
                        name="gender"
                        className={`mt-1`}
                        onChange={handleStudyLevelSelect}
                        required
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value={"MALE"}>Etudiant</option>
                        <option value="FEMALE">Etudiante</option>
                      </Select>
                    </label>
                    {/* birthdate */}
                    <label className="block">
                      <span className="text-neutral-800 dark:text-neutral-200">
                        Date de naissance*
                      </span>
                      <Input
                        type="date"
                        className="mt-1"
                        name="birthDate"
                        onChange={handleChange}
                        required
                      />
                    </label>
                    {/* Password */}
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Mot de passe*
                      </span>
                      <Input
                        required
                        type="password"
                        placeholder="******"
                        className="mt-1"
                        name="password"
                        onChange={handleChange}
                        onBlur={handlePasswordCharacters}
                        ref={passwordRef}
                      />
                      <span
                        className="text-xs text-red-500 hidden"
                        ref={passwordErrRef}
                      >
                        Password must be between 6 to 20 characters
                      </span>
                    </label>
                    {/* Password */}
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Confirmer le mot de passe*
                      </span>
                      <Input
                        required
                        type="password"
                        placeholder="******"
                        className="mt-1"
                        name="confirmPassword"
                        onChange={handleChange}
                      />
                      <span
                        className="text-xs text-red-500 hidden"
                        ref={confirmPasswordErrRef}
                      >
                        Le mot de passe doit être le même !
                      </span>
                    </label>
                    {/* user Profile */}
                    <label className="block">
                      <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                        Une photo de profil*
                      </span>
                      <input
                        className="mt-1 leading-9 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        aria-describedby="file_input_help"
                        id="file_input"
                        type="file"
                        accept=".jpg,.png,.jpeg,"
                        onChange={handleFile}
                      />
                      <p
                        className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        id="file_input_help"
                      >
                        JPEG, PNG, JPG
                      </p>
                    </label>
                  </div>
                  {signupErr && (
                    <span className="text-red-500 text-sm">{signupErr}</span>
                  )}
                </div>
                {/* Submit */}
                <ButtonPrimary
                  disabled={isSubmiting}
                  className={`w-full rounded-md
                              ${
                                isSubmiting
                                  ? "bg-gray-500 cursor-not-allowed"
                                  : ""
                              }`}
                  type="submit"
                >
                  Crèer mon compte
                </ButtonPrimary>
              </form>
              {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  Ou
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              <ButtonPrimary
                className="w-full rounded-md"
                onClick={() => setOpenAuthForm("login")}
              >
                Se connecter
              </ButtonPrimary>
            </div>
          ) : (
            ""
          )}
          {!openAuthForm && (
            <>
              {/* Create Account */}
              <div className="flex justify-center flex-col items-center gap-4">
                <h1 className="text-base font-semibold">
                  Nouvel utilisateur CoursePlusPlus?
                </h1>
                <ButtonPrimary
                  className="w-full rounded-md"
                  onClick={() => setOpenAuthForm("signup")}
                >
                  Créer mon compte
                </ButtonPrimary>
              </div>
              {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OU
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              {/* Login */}
              <div className="flex justify-center flex-col items-center gap-4">
                <h1 className="text-base font-semibold">
                  Avez-vous déjà utilisé CoursePlusPlus 👀?
                </h1>

                <ButtonPrimary
                  className="w-full rounded-md"
                  onClick={() => setOpenAuthForm("login")}
                >
                  Se connecter
                </ButtonPrimary>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          Informations sur l'enseignant
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
            imgUrl={`${
              teacherDetails?.photos.length !== 0
                ? `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${teacherDetails?.photos[0]?.s3Url}`
                : ""
            }`}
            userName={teacherDetails?.firstName.charAt(0)}
          />
          <div>
            <Link
              className="block text-xl font-medium"
              to="/teacher"
              state={{ teacherId: teacherDetails?.id }}
            >
              {`${teacherDetails?.firstName} ${teacherDetails?.lastName}`}
            </Link>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating
                point={teacherDetails?.averageTeacherRating}
                reviewCount={teacherDetails?.totalTeacherReviews}
              />
              <span className="mx-2">·</span>
              <span> Avis</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          {teacherDetails?.aboutMe}
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>
              {teacherDetails?.registrationDate
                ? `Inscrit en ${new Date(
                    teacherDetails.registrationDate
                  ).toLocaleDateString("fr-FR", {
                    month: "long",
                    year: "numeric"
                  })}`
                : "Date d'inscription inconnue"}
            </span>
          </div>

          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16c-4.418 0-8-1.79-8-4V4c4.418 0 8 1.79 8 4zm0 0v16c4.418 0 8-1.79 8-4V4c-4.418 0-8 1.79-8 4z"
              />
            </svg>
            <span>Langue : Arabe 🇲🇦</span>
          </div>

          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-green-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>

            <span>Compte vérifié</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <Link
            className="ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 p-4 rounded-full"
            to="/teacher"
            state={{ teacherId: teacherDetails?.id }}
          >
            Voir le profil de l'enseignant
          </Link>
        </div>
      </div>
    )
  }

  const renderLoginModal = () => {
    return (
      <>
        <Transition appear show={loginModal} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeLoginModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                    >
                      Connexion
                    </Dialog.Title>
                    <div className="mt-2 mb-4">
                      <p className="text-sm text-gray-500">
                        Merci de vous connecter pour continuer (seul les
                        étudiants deja inscrits peuvent laisser un avis)
                      </p>
                    </div>

                    <div className="mt-4 flex gap-4">
                      <ButtonPrimary href={"/login"}>
                        Se connecter
                      </ButtonPrimary>
                      <ButtonPrimary onClick={closeLoginModal}>
                        Annuler
                      </ButtonPrimary>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }

  const handleAddReview = () => {
    if (user) {
      if (addReview) {
        // if User Not Selected Stars
        let ratingErrSpan = ratingErr.current
        if (ratingErrSpan) {
          if (!reviewStars) {
            ratingErrSpan.style.display = "block"
          } else {
            ratingErrSpan.style.display = ""

            let c_id = Number(courseId)

            ApiService.addReview(
              user.token,
              user.id,
              c_id,
              addReview,
              reviewStars
            )
              .then((res) => {
                if (res.data.status === "FAIL") {
                  setRattingResErr(res.data.error)
                } else {
                  if (res.data.data) {
                    setRattingResSuccess(res.data.data)
                  } else {
                    setRattingResSuccess(res.data.details)
                  }
                }
              })
              .catch((err) => console.error(err))
          }
        }
      }
    } else {
      openLoginModal()
    }
  }

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          Avis ({courseDetails?.totalCourseReviews} total des avis)
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          Seul les étudiants inscrits dans ce cours peuvent laisser un avis.
        </span>
        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate
            onRatingChange={handleRatingSelection}
            iconClass="w-6 h-6"
            className="space-x-0.5"
          />
          <span ref={ratingErr} className="text-xs text-red-500 hidden">
            Please Select Rating Stars
          </span>
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Envoyez votre avis ..."
              value={addReview}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setAddReview(e.target.value)
              }}
            />
            <ButtonCircle
              className={`absolute right-2 top-1/2 transform -translate-y-1/2 ${
                addReview ? "" : "bg-gray-500 cursor-not-allowed"
              }`}
              size="w-12 h-12"
              onClick={handleAddReview}
              disabled={addReview ? false : true}
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
          <span className="text-xs text-red-500">{rattingResErr}</span>
          <span className="text-xs text-green-500">{rattingResSuccess}</span>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {courseDetails?.reviews.map((review, idx) => (
            <CommentListing
              key={idx}
              avatar={review.studentPhoto}
              comment={review.comment}
              date={review.postedDate}
              name={review.studentFirstName}
              startPoint={review.rating}
              subscriptionType={review.subscriptionType}
              className="py-8"
            />
          ))}
        </div>
      </div>
    )
  }

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Informations importantes</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Politique d'annulation ❌</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Notre politique d'annulation est conçue pour être équitable et
            transparente, offrant des options flexibles tant pour les étudiants
            que pour les professeurs.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h3 className="text-lg font-semibold">Détails d'annulation</h3>
          <div className="prose sm:prose mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
            <div>
              <h4 className="font-medium text-neutral-700 dark:text-neutral-300">
                🧑‍🏫 Annulation par le Professeur :
              </h4>
              <h5 className="font-medium text-neutral-700 dark:text-neutral-300">
                Interruption des cours par le professeur :
              </h5>
              Remboursement total du prix de l’abonnement et des frais de
              service.
              <br /> <br />
              <h5 className="font-medium text-neutral-700 dark:text-neutral-300">
                Arrêt des cours d’un groupe :
              </h5>
              Avant le 15 du mois : Remboursement total du prix de l’abonnement.{" "}
              <br />
              Après le 15 du mois : Remboursement de 50 % du prix de
              l’abonnement.
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            <div>
              <h4 className="font-medium text-neutral-700 dark:text-neutral-300">
                👨‍🎓 Annulation par l'Étudiant :
              </h4>
              <h5 className="font-medium text-neutral-700 dark:text-neutral-300">
                Avant la 3ème séance d'essaie:
              </h5>
              Remboursement total.
              <br /> <br />
              <h5 className="font-medium text-neutral-700 dark:text-neutral-300">
                Après la 3ème séance d'essaie:
              </h5>
              Demande avant la fin du mois, sans remboursement pour les séances
              effectuées.
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            <div>
              <h4 className="font-medium text-neutral-700 dark:text-neutral-300">
                📝 Procédure d'Annulation :
              </h4>
              Connectez-vous à votre compte et suivez les instructions. Les
              remboursements seront traités sous 7 à 10 jours ouvrables.
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            <div>
              <h4 className="font-medium text-neutral-700 dark:text-neutral-300">
                🆘 Cas d'Urgence :
              </h4>
              Contactez notre support :
              <p>
                Email :{" "}
                <a
                  className="font-medium text-neutral-700 dark:text-neutral-300"
                  href="mailto:contact@coursplusplus.com"
                >
                  contact@coursplusplus.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderOtherSection = () => {
    return (
      <div className="container py-18 lg:py-10">
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>
    )
  }

  // if (!courseDetails || !teacherDetails) {
  //   return (
  //     <div className="flex flex-col">
  //       <div className=" bg-gray-200 dark:bg-gray-700 w-full rounded-md animate-pulse max-w-[100vw] lg:max-w-[50vw,50vw] h-[600px]"></div>
  //       <div className=" bg-gray-200 dark:bg-gray-700  rounded-md animate-pulse w-full h-[200px] mt-11 mb-11"></div>
  //     </div>
  //   );
  // }

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative rounded-md sm:rounded-xl overflow-hidden max-h-[600px] flex justify-center items-center ">
          <img
            className="inset-0  rounded-md sm:rounded-xl w-full h-full"
            src={`${
              courseDetails?.photos.length !== 0
                ? `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${courseDetails?.photos[0].s3Url}`
                : teacherDetails?.photos.length !== 0
                ? `https://cpp-photo-bucket.s3.eu-north-1.amazonaws.com/${teacherDetails?.photos[0].s3Url}`
                : "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
            }`}
            alt=""
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
          />
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col  ">
        {/* CONTENT */}
        <div className="w-full  space-y-8 lg:space-y-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSlots()}
          {renderSection5()}
          {renderSection6()}
          {renderSection8()}
          {renderLoginModal()}
          {renderOtherSection()}
        </div>
      </main>
    </div>
  )
}

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  )
}

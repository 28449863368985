import React, { FC, useEffect, useState } from "react"

import TeachersInput from "../TeachersSearchInput"
import { ApiService } from "service/ApiService"
import { TeacherDataTypes } from "data/types"

interface Props {
  setTeacherId: (val: number) => void
}

const SearchByTeacher: FC<Props> = ({ setTeacherId }) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<"Teacher" | "">("Teacher")
  //
  const [teacher, setTeacher] = useState("")

  const [allTeachers, setAllTeachers] = useState<TeacherDataTypes[]>([])

  useEffect(() => {
    ApiService.getAllTeachers()
      .then((res) => setAllTeachers(res.data.data))
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    allTeachers.map((tchr) => {
      if (teacher === `${tchr.firstName} ${tchr.lastName}`) {
        setTeacherId(tchr.id)
      }
    })
  }, [teacher])

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "Teacher"
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("Teacher")}
          >
            <span className="text-neutral-400">Teacher</span>
            <span>{teacher || "Teacher"}</span>
          </button>
        ) : (
          <TeachersInput
            defaultValue={teacher}
            data={allTeachers}
            onChange={(value) => {
              setTeacher(value)
              setFieldNameShow("")
            }}
            headingText="Rechercher un professeur"
          />
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {renderInputLocation()}
      </div>
    </div>
  )
}

export default SearchByTeacher

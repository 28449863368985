import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { StudyLevelsTypes } from "data/types"
import React, { useState, useEffect, useRef, FC } from "react"
import { ApiService } from "service/ApiService"

interface Props {
  onClick?: () => void
  onChange?: (value: string) => void
  className?: string
  defaultValue?: string
  headingText?: string
}

const StudyLevels: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "Niveau d'étude",
  headingText = "Niveau d'étude"
}) => {
  const [studyLevels, setStudyLevels] = useState<StudyLevelsTypes[]>([])

  useEffect(() => {
    ApiService.getAllStudyLevels()
      .then((res) => {
        setStudyLevels(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])
  const [value, setValue] = useState("")
  const containerRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleSelectLocation = (item: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item)
      onChange && onChange(item)
    }, 0)
  }

  const renderSearchValues = ({ heading }: { heading: string }) => {
    return (
      <>
        <p className="block font-semibold text-base">{heading}</p>
        <div className="mt-3">
          {studyLevels.length > 0
            ? studyLevels
                .filter((studyLevel) =>
                  studyLevel.name.toLowerCase().includes(value.toLowerCase())
                )
                .map((item) => {
                  return (
                    <div
                      className="py-2 mb-1 flex items-center space-x-3 text-sm"
                      onClick={() => handleSelectLocation(item.name)}
                      key={item.id}
                    >
                      <EyeIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                      <span className="">{item.name}</span>
                    </div>
                  )
                })
            : "loading"}
          {studyLevels.length > 0 &&
            studyLevels.filter((studyLevel) =>
              studyLevel.name.toLowerCase().includes(value.toLowerCase())
            ).length === 0 && <div>No value found</div>}
        </div>
      </>
    )
  }

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Rechercher un niveau d'étude"}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {renderSearchValues({
            heading: "Niveau d'étude"
          })}
        </div>
      </div>
    </div>
  )
}

export default StudyLevels

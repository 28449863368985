import rightImg from "images/about-hero-right.png"
import React, { FC } from "react"
import SectionFounder from "./SectionFounder"
import SectionStatistic from "./SectionStatistic"
import { Helmet } from "react-helmet"
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2"
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism"
import BackgroundSection from "components/BackgroundSection/BackgroundSection"
import SectionHero from "./SectionHero"
import SectionClientSay from "components/SectionClientSay/SectionClientSay"

export interface PageAboutProps {
  className?: string
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Cours PlusPlus</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 Qui Nous Sommes"
          btnText=""
          subHeading={
            <span>
              Nous sommes un collectif d'enseignants et d'étudiants et motivés
              par une mission :{" "}
              <strong>
                rendre l' éducation plus accessible au Maroc grâce à la{" "}
                technologie.{" "}
              </strong>{" "}
              <br />
              Notre plateforme met en relation des étudiants en quête de
              connaissances supplémentaires avec des enseignants expérimentés,
              offrant ainsi des cours flexibles qui s'adaptent à différents
              emplois du temps.
            </span>
          }
        />

        <SectionFounder />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionStatistic />

        <SectionSubscribe2 />
      </div>
    </div>
  )
}

export default PageAbout

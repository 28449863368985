import React, { FC } from "react"
import Logo from "shared/Logo/Logo"
import Navigation from "shared/Navigation/Navigation"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import MenuBar from "shared/MenuBar/MenuBar"
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode"
import HeroSearchForm2Mobile from "components/HeroSearchForm2Mobile/HeroSearchForm2Mobile"
import { useAuth } from "service/Auth"

export interface MainNav1Props {
  className?: string
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, clearSession } = useAuth()
  const handleLogout = () => {
    clearSession()
  }

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-2 lg:py-2 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2Mobile />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SwitchDarkMode />
            <div className="px-1 flex gap-4" />
            {user?.token ? (
              <div className="flex gap-3">
                <ButtonPrimary href="https://dashboard-dev.coursplusplus.com/">
                  Dashboard
                </ButtonPrimary>
                <ButtonPrimary href="#" onClick={handleLogout}>
                  Se deconnecter
                </ButtonPrimary>
              </div>
            ) : (
              <div className="flex gap-3">
                {" "}
                {/* Assurez-vous que le gap est conforme à votre design */}
                <ButtonPrimary href="/signup/teacher">
                  Devenir Professeur
                </ButtonPrimary>
                <ButtonPrimary href="/login">Se connecter</ButtonPrimary>
              </div>
            )}
          </div>

          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainNav1

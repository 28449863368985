import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1"
import Heading from "components/Heading/Heading"
import { SubjectTypes } from "data/types"
import React from "react"

export interface SectionGridCategoryBoxProps {
  categories?: SubjectTypes[]
  headingCenter?: boolean
  categoryCardType?: "card1"
  className?: string
  gridClassName?: string
}

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
}) => {
  let CardComponentName = CardCategoryBox1
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1
      break

    default:
      CardComponentName = CardCategoryBox1
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc="Plongez dans notre vaste sélection de matières adaptées à tous les curieux."
        isCenter={headingCenter}
      >
        Nos Matières 🎓
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {categories?.slice(0, 8).map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />
        ))}
      </div>
    </div>
  )
}

export default SectionGridCategoryBox

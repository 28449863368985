import React, { FC } from "react"
import rightImgPng from "images/our-features.png"
import NcImage from "shared/NcImage/NcImage"
import Badge from "shared/Badge/Badge"

export interface SectionOurFeaturesProps {
  className?: string
  rightImg?: string
  type?: "type1" | "type2"
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1"
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          Avec Cour PlusPlus
        </span>
        <h2 className="font-semibold text-4xl mt-5">
          Transformez Votre Apprentissage{" "}
        </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="100% en ligne" />
            <span className="block text-xl font-semibold">
              Apprentissage Flexible
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Étudiez de n'importe où, sans déplacement. Adaptez les cours à
              votre emploi du temps.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Exposure " />
            <span className="block text-xl font-semibold">
              Suivi Personnalisé
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Bénéficiez d'un accompagnement sur mesure grâce à accès direct à
              des enseignants qualifiés et reconnus.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Paiements Sécurisés" />
            <span className="block text-xl font-semibold">
              Réservation Instantanée
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Choisissez et réservez votre cours en quelques clics, sans
              attente.
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SectionOurFeatures

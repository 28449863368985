import React from "react"
import ButtonClose from "shared/ButtonClose/ButtonClose"
import Logo from "shared/Logo/Logo"
import { NavLink } from "react-router-dom"
import ButtonPrimary from "shared/Button/ButtonPrimary"
import SocialsList from "shared/SocialsList/SocialsList"
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode"
import LangDropdown from "components/Header/LangDropdown"
import { useAuth } from "service/Auth"

export interface NavMobileProps {
  onClickClose?: () => void
}

const NavMobile: React.FC<NavMobileProps> = ({ onClickClose }) => {
  const { clearSession, user } = useAuth()
  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>
            Rejoignez <strong> la révolution</strong> de l'apprentissage avec{" "}
            Cours PlusPlus : où chaque étudiant trouve sa voie vers l'excellence
            🚀
          </span>

          <div className="flex justify-between items-center mt-4">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary  bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/"}
        >
          <span className={`py-2.5 pr-3`}>Accueil</span>
        </NavLink>
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/about"}
        >
          <span className={`py-2.5 pr-3`}>A propos</span>
        </NavLink>
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/contact"}
        >
          <span className={`py-2.5 pr-3`}>Contactez-nous</span>
        </NavLink>
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive
                ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                : ""
            }`
          }
          to={"/blog"}
        >
          <span className={`py-2.5 pr-3`}>Blog</span>
        </NavLink>
        {user && (
          <NavLink
            end
            className={({ isActive }) =>
              `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
                isActive
                  ? "text-secondary bg-neutral-100 dark:bg-neutral-800"
                  : ""
              }`
            }
            to={"#"}
            onClick={clearSession}
          >
            <span className={`py-2.5 pr-3`}>se déconnecter</span>
          </NavLink>
        )}
      </ul>
      <div className="flex items-center justify-between py-6 px-5">
        {user ? (
          <ButtonPrimary href={"#"}>Dashboard</ButtonPrimary>
        ) : (
          <ButtonPrimary href="/login">Se connecter</ButtonPrimary>
        )}

        <LangDropdown panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 -right-3 bottom-full sm:px-0" />
      </div>
    </div>
  )
}

export default NavMobile

import React, { FC, useState } from "react"
import ButtonCircle from "shared/Button/ButtonCircle"
import rightImg from "images/SVG-subcribe2.png"
import NcImage from "shared/NcImage/NcImage"
import Badge from "shared/Badge/Badge"
import Input from "shared/Input/Input"

export interface SectionSubscribe2Props {
  className?: string
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [email, setEmail] = useState("")
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [error, setError] = useState("")

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError("")

    // Validation de l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setError("Veuillez entrer une adresse email valide.")
      return
    }

    // URL d'inscription Mailchimp
    const mailchimpUrl =
      "https://coursplusplus.us22.list-manage.com/subscribe/post?u=eeffc76905004419a511835e2&id=02df85dc04"

    // Préparer les données à envoyer
    const formData = new FormData()
    formData.append("EMAIL", email)

    try {
      await fetch(mailchimpUrl, {
        method: "POST",
        body: formData,
        mode: "no-cors"
      })
      setIsSubscribed(true)
      setEmail("")
    } catch (err) {
      setError("Une erreur s'est produite. Veuillez réessayer.")
    }
  }

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Restez Connectés 📨 🔔</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Abonnez-vous à notre newsletter pour ne rien manquer de Cours
          PlusPlus.
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Actualités Exclusives 💡
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="green" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Conseils Pédagogiques 📚
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="03" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Offres Spéciales .. 🎁 😉
            </span>
          </li>
        </ul>
        <form onSubmit={handleSubmit} className="mt-10 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="Entrez votre email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            rounded="rounded-full"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
        {error && <p className="mt-4 text-red-500">{error}</p>}
        {isSubscribed && (
          <p className="mt-4 text-green-500">Merci pour votre abonnement !</p>
        )}
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  )
}

export default SectionSubscribe2
